/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// TODO: implment the following metatags
// <link rel="canonical" href="https://www.jsfour.com/cryptocurrency-sentiment-reddit/" />
// <meta property="og:url" content="https://www.jsfour.com/cryptocurrency-sentiment-reddit/" />
// <meta property="og:image" content="https://www.jsfour.com/assets/images/disentinel-r-btc.png" />
// <meta property="og:type" content="article" />
// <meta property="article:published_time" content="2019-11-12T00:00:00+00:00" />
// <meta name="twitter:card" content="summary_large_image" />
// <meta name="twitter:site" content="@jsfour" />
// <script type="application/ld+json">
// {"datePublished":"2019-11-12T00:00:00+00:00","@type":"BlogPosting","mainEntityOfPage":{"@type":"WebPage","@id":"https://www.jsfour.com/cryptocurrency-sentiment-reddit/"},"url":"https://www.jsfour.com/cryptocurr
// ency-sentiment-reddit/","author":{"@type":"Person","name":"Jimi Smoot"},"image":"https://www.jsfour.com/assets/images/disentinel-r-btc.png","headline":"Sentiment Analysis Of Cryptocurrency Subreddits","descript
// ion":"A few weekends ago, I wrote a Reddit sentiment analysis program and applied it to the crypto markets. Lets take a look how it panned out.","dateModified":"2019-11-12T00:00:00+00:00","@context":"http://sch
// ema.org"}</script>

// TODO: Bring images into the seo tag
// TODO: bring in published_time
// TODO: calculate the URL somehow
function SEO({
  children,
  description,
  lang,
  meta,
  title,
  slug,
  timestamp,
  image,
  jsonLd,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitter
            siteImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const imageLocation = image || site.siteMetadata.siteImage
  const metaImage = `${site.siteMetadata.siteUrl}/static/${imageLocation}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s · ${site.siteMetadata.title}`}
      meta={[
        {
          property: "og:image",
          content: metaImage,
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:site_name",
          content: site.siteMetadata.author,
        },
        {
          property: `author`,
          content: site.siteMetadata.author,
        },
        {
          property: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  timestamp: PropTypes.any, // Moment
}

export default SEO
